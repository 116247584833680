@font-face 
{
  font-family: 'splash';
  src: url('./resources/fonts/Splash-Regular.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'montserrat';
  src: url('./resources/fonts/Montserrat-Medium.ttf');
}

body 
{
  margin: 0;
  background-color: white;
}

.paper-sheet
{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 25px;
}

.game-board
{
  display: flex;
  flex-wrap: wrap;
  width: 300px;
  height: 300px;
  margin: 0 auto;
  margin-top: 25px;
}

.cell 
{
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  border: 1px solid black;
  box-sizing: border-box;
  user-select: none;
  font-family: 'splash'
}

.paper-sheet-img
{
  z-index: -1;
  position: absolute;
  width: 75%;
  height: auto;
  transform-origin: center center;
  left: 50%;
  transform: translate(-50%);
}

.results
{
  text-align: center;
  user-select: none;
  margin: 0 auto;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 100px;
}

.retry-btn
{
  padding: 15px 30px;
  border: 0;
  background-color: orange;
  color: white;
  font-size: 20px;
  cursor: pointer;
  transition: 0.3s all ease-in-out;
  text-transform: uppercase;
}

.retry-btn:hover
{
  font-size: 25px;
}

.multiplayer-create-form
{
  width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 25px;
  font-family: Arial, Helvetica, sans-serif;
}

.multiplayer-create-form input 
{
  padding: 15px 30px;
  font-size: 15px;
  outline: 0;
}

.multiplayer-create-form input[type=submit]
{
  background-color: green;
  color: white;
  cursor: pointer;
  border: 2px solid black;
}

.multiplayer-join-form input[type=submit]
{
  background-color: orange;
  color: white;
  cursor: pointer;
  border: 2px solid black;
}

.multiplayer-form-container
{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  width: fit-content;
  margin: 0 auto;
}

.join-game-error
{
  color: red;
  font-family: Arial, Helvetica, sans-serif;
}

header 
{
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
}

header 
{
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

/*font-family: 'montserrat';*/
}

.game-info 
{
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.stats-text
{
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.header-btn-container 
{
  display: flex;
  align-items: center;
  gap: 10px;
}

footer 
{
  /*position: absolute;
  bottom: 0;
  padding: 10px;*/
  margin: 10px;
  font-family: Arial, Helvetica, sans-serif;
}

.invite-link-container
{
  display: flex;
  align-items: center;
  margin-top: 10px;
  width: fit-content;
  margin: 0 auto;
  gap: 5px;
}

.invite-link-container input 
{
  border: 2px solid gray;
  border-radius: 5px;
  font-size: 15px;
}

.invite-link-container button 
{
  padding: 10px;
  color: white;
  background-color: green;
  font-size: 15px;
  border: 2px solid black;
  cursor: pointer;
}

.error-page-container 
{
  margin: 100px;
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.chat-container 
{
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.chat-form 
{
  display: flex;
  justify-content: center;
}

.chat-form textarea
{
  flex-grow: 1;
}

.chat-msg-container 
{
  height: 200px;
  overflow: hidden;
  font-family: 'montserrat';
}

.play-online-text
{
  text-align: center;
  font-family: Arial, Helvetica, sans-serif;
}

.send-message-submit 
{
  background-color: orange;
  color: white;
  border: 0;
  outline: 0;
}

@media screen and (max-width: 800px)
{
  .error-page-container 
  {
    margin: 25px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
  }
  
  .stats-text
  {
    margin: 10px;
  }

  .chat-container 
  {
    padding: 5px;
  }

  .send-message-submit 
  {
    background-color: orange;
    color: white;
    border: 0;
    outline: 0;
    width: 100px;
    height: 75px;
    font-size: 20px;
  }
}